import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '../element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css'

import locale from 'element-ui/lib/locale';
import en from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
// 设置ElementUI的语言
locale.use(en);

Vue.config.productionTip = false
Vue.use(ElementUI)

import httpinfo from './assets/js/httpinfo.js'
Vue.prototype.httpinfo = httpinfo
Vue.use(httpinfo)

import base32 from './assets/js/base32.min.js'
Vue.prototype.base32 = base32
Vue.use(base32)

import axios from 'axios'
Vue.prototype.$axios = axios

// Vue.prototype.baseURL = 'https://tvyuki.top/'
Vue.prototype.baseURL = 'https://data.gmgg.xyz/'
// Vue.prototype.baseURL = 'http://127.0.0.1/'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
