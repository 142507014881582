<template>
  <div class="page" v-loading="loading">

    <!-- <Nav activeIndex="1"></Nav> -->
    <!-- <VideoNav :activeIndex="type" ></VideoNav> -->

    
    <div id="img_video">

      <el-row>
        <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6" style="padding: 0px 2px;" v-for="(item,index) in adlist_10" :key="index">

          <el-card :body-style="{ padding: '0px' }" class="box-card">
            <a :href="item.url" target="_blank"><img style="width: 100%;" :src="item.img" alt=""></a>
          </el-card>
          
        </el-col>
      </el-row>
      
    
      <el-row>
        <el-col :xs="12" :sm="6" :md="6" :lg="4" :xl="4" style="padding: 10px;" v-for="(item,index) in dataList" :key="index">
          
          <el-card :body-style="{ padding: '0px',width:'100%',height:'185px' }">
            <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"> -->
            <span @click="goTo('/VideoPlayer?id='+item.ids+'&tit='+item.title+'&tags='+item.tags)">
              <el-image name="img_video" class="el_image img_video" :src="item.image" fit="contain">
                <div slot="error" class="image-slot">
                  <img src="@/assets/no_img.png" fit="contain">
                </div>
                <div slot="placeholder" class="image-slot">
                  <img src="@/assets/loading.gif" fit="contain">
                </div>
              </el-image>
            </span> 
            <div style="padding: 10px;">
              <div style="font-size: 0.7rem;width:100%;">{{ item.title }}</div>
            </div>
          </el-card>
          
        </el-col>
        <el-col>
          <div style="padding-top: 20px;" >
            <el-pagination
              background
              layout="prev, pager, next"
              @prev-click="prevPage"
              @next-click="nextPage"
              @current-change="currentChange"
              :pager-count="5"
              :page-size="24"
              :current-page="pagePage"
              :total="Total">
            </el-pagination>
          </div> 
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6" style="padding: 0px 2px;" v-for="(item,index) in adlist_11" :key="index">

          <el-card :body-style="{ padding: '0px' }" class="box-card">
            <a :href="item.url" target="_blank"><img style="width: 100%;" :src="item.img" alt=""></a>
          </el-card>
          
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="1" class="subtitle">
          <h3>동맹사이트</h3>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3" style="padding: 10px;" v-for="(item, index) in linklist" :key="index">
          <div class="one_line_hidden" style="font-weight: bold;"><a style="color: red;text-decoration: none;" :href="baseURL + 'urlto/' + item.short" target="_blank">{{ item.title }}</a></div>
        </el-col>
      </el-row>
      
    </div>
       
  </div>
</template>

<script>
// import Nav from '@/components/Nav.vue'
// import VideoNav from '@/components/VideoNav.vue'
export default {
  name: 'PageType',
  components: {
    // Nav,
    // VideoNav
  },
  data() {
    return {
      loading:true,
      Tags:'',
      dataList:[],
      adlist_10:[],
      adlist_11:[],
      linklist:[],
      pagePage:0,
      pageTotal:0,
      type:'list',
      Total:0
    };
  },
  watch: {
    // 监听路由对象 $route 的变化
    '$route': {
      handler: function (to, from) {
        // 路由变化时执行的操作
        this.onRouteChange(to, from);
        this.Tags = this.$route.query.tags==undefined?'':this.$route.query.tags
        this.type = this.$route.query.type
        this.pagePage = parseInt(this.$route.query.page)
        this.pagePage = this.pagePage==undefined?'1':this.pagePage

        this.loading = true
        this.loadPage("type="+this.type+"&tags="+this.Tags+"&page="+to.query.page)
      },
      // 设置为深度监听
      deep: true
    }
  },
  created() {
    this.Tags = this.$route.query.tags==undefined?'':this.$route.query.tags
    // console.log(this.type)
    this.pagePage = parseInt(this.$route.query.page)
    this.pagePage = this.pagePage==undefined?'1':this.pagePage
    // console.log(this.pagePage)
    this.loadPage("type="+this.type+"&tags="+this.Tags+"&page=" + this.pagePage)
  },
  methods: {
    loadPage(Query){
      
      this.dataList = [];this.adlist_10 = [];this.adlist_11 = [];this.linklist = []
      var that = this,dataList = this.dataList,adlist_10=this.adlist_10,adlist_11=this.adlist_11,linklist=this.linklist


      this.httpinfo.GetInfo(this.baseURL + "xv?"+Query, function(e) {
        if(e.data.code==0){

          that.pageTotal = e.data.pagecount
          that.Total = e.data.count

          e.data.data.forEach(element => {
            // element.image = 'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/b9/e7/e4/b9e7e44b889f8eebfb46fc53377f8df3/b9e7e44b889f8eebfb46fc53377f8df3.11.jpg'
            dataList.push(element)
          });
          
          dataList = []

          e.data.linklist.forEach(element => {
            linklist.push(element)
          });
          
          try {
            e.data.adlist.ad_10.forEach(element => {
              adlist_10.push(element)
            });
            adlist_10 = []
          } catch (error) {
            
          }
          

          try {
            e.data.adlist.ad_11.forEach(element => {
              adlist_11.push(element)
            });
          } catch (error) {
            
          }

        }else{
          that.$message.error(e.data.msg)
        }

        that.loading =false
      });
    },
    prevPage(e){
      // console.log(e)
    },
    nextPage(e){
      // console.log(e)
    },
    currentChange(e){
      // console.log(e)
      // console.log(this.$router.currentRoute.fullPath)
      this.goTo("?type="+this.type+"&tags="+this.Tags+"&page="+e)
    },
    goTo(path) {
      // console.log(path)
      if (path !== this.$router.currentRoute.fullPath) {
        
          this.loading = true
          this.$router.push(path);
      }
    },
    onRouteChange(to, from) {
      // // 你的逻辑代码
      // console.log('Route changed from', from.path, 'to', to.path);
      // this.loading = true
      // this.loadPage("type="+this.type+"&tags="+this.Tags+"&page="+to.query.page)
    }
  }
}
</script>
<style>
  .subtitle{
    text-align: left;
    min-width: 150px;
  }
  .el_image img{
    padding: 0;
    width: 100%;
    /* width: 160px;
    height: 90px; */
  }
  .tag_list .el-tag{
    margin: 5px;
    padding: 0 10px;
  }
</style>