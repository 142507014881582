import Vue from 'vue'
import VueRouter from 'vue-router'
import PageType from '../views/PageType.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PageType',
    component: () => import('../views/PageType.vue')
  },
  {
    path: '/VideoPlayer',
    name: 'VideoPlayer',
    component: () => import('../views/VideoPlayer.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
