<template>
  <div id="app" class="page">
    <el-row :gutter="24" type="flex" align="top" >
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"  type="flex" align="top" justify="center">
        <div>
          <!-- <router-link to="/#/PageType/"> -->
            <a href="/"><img src="@/assets/logo.png" alt="TV Yuki" style="width: 100px;"></a>
          <!-- </router-link> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" justify="center" style="text-align: right;">
        <el-button @click="drawer = true" type="primary" style="margin-left: 16px;">
          All tags
        </el-button>
      </el-col>
    </el-row>
    <!-- <nav>  
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->

    


    <el-drawer v-loading="loading" style="" title="All tags" :visible.sync="drawer" :direction="direction">
      <el-row class="tag_list" justify="start">
        <el-col :span="24" style="text-align: left;">

          <el-tag v-for="(item,index) in tags" :key="index"><router-link style="color: red;text-decoration: none;" :to="'/?type=list&tags='+item+'&page=1'">{{item}}</router-link></el-tag>
          

          <el-divider content-position="right">^_^</el-divider>
        </el-col>
      </el-row>
    </el-drawer>

    <router-view/>

    <el-divider content-position="right">gmgg.xyz</el-divider>
  </div>
</template>
<script>
// import Nav from '@/components/Nav.vue'
// import VideoNav from '@/components/VideoNav.vue'
export default {
  data(){
    return {
      drawer: false,
      loading:true,
      direction: 'ttb',
      tags:[]
    }
  },
  created() {
    document.title = '구멍가계';
    var that = this
    this.httpinfo.GetInfo(this.baseURL + "xv?type=keys", function(e) {
        if(e.data.code==0){

          that.tags = e.data.data.keys.split(',')

        }else{
          that.$message.error(e.data.msg)
        }
        
        that.loading =false
      });
    
  }
}
</script>
<style>
.page{
  max-width:1200px;
  margin: 0 auto;
  color:red;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.el-tag{
  margin: 5px;
  padding: 0 10px;
}
</style>
